import { Article } from 'components/article/types'
import { routes } from 'utils/routes'
import dayjs from 'dayjs'
import { Language } from 'types/language'
import { CmsBlogArticle, CmsBlogExternalArticle, CmsEncyclopediaArticle } from 'types/cms/collection-types'
import { CmsArticleTypes } from 'types/cms/enums'

/**
 * Helper function that sorts & maps an array of CmsBlogArticle objects to an array of Article objects
 *
 * @param {CmsBlogArticle[]} cmsBlogArticles
 * @param {number} [limit]
 * @returns {Article[]}
 */
export const mapAndSortCmsBlogArticlesToArticles = (cmsBlogArticles: CmsBlogArticle[], limit: number = 4): Article[] => {
  const articles = mapCmsBlogArticlesToArticles(cmsBlogArticles)
  return sortArticlesOnPublishedDate(articles, limit)
}

/**
 * Helper function that maps a CmsBlogArticle object to an Article object
 *
 * @param {CmsBlogArticle} cmsBlogArticle
 * @returns {Article}
 */
export const mapCmsBlogArticleToArticle = (cmsBlogArticle: CmsBlogArticle): Article => {
  return {
    author: {
      avatarPublicId: cmsBlogArticle.attributes.author.data?.attributes.avatar.data?.attributes.provider_metadata.public_id ?? null,
      avatarUrl: cmsBlogArticle.attributes.author?.data?.attributes?.avatar?.data?.attributes.url ?? null,
      firstName: cmsBlogArticle.attributes.author?.data?.attributes?.firstName,
      personalizedFooter: cmsBlogArticle.attributes.author?.data?.attributes?.personalizedFooter ?? null
    },
    ...(cmsBlogArticle.attributes.category.data && {
      category: {
        slug: cmsBlogArticle.attributes.category.data.attributes.slug,
        title: cmsBlogArticle.attributes.category.data.attributes.title
      }
    }),
    contentBlocks: cmsBlogArticle.attributes.contentBlocks ?? null,
    description: cmsBlogArticle.attributes.description,
    destination: routes(cmsBlogArticle.attributes.locale, cmsBlogArticle.attributes.slug).blogArticle,
    id: cmsBlogArticle.id,
    locale: cmsBlogArticle.attributes.locale as Language,
    publishedAt: cmsBlogArticle.attributes.customPublishedAt ?? cmsBlogArticle.attributes.publishedAt,
    slug: cmsBlogArticle.attributes.slug,
    thumbnail: cmsBlogArticle.attributes.thumbnail,
    title: cmsBlogArticle.attributes.title,
    type: CmsArticleTypes.BLOG_INTERNAL
  }
}

/**
 * Helper function that maps a CmsBlogArticle object to an Article object
 *
 * @param {CmsBlogArticle[]} cmsBlogArticles
 * @returns {Article[]}
 */
export const mapCmsBlogArticlesToArticles = (cmsBlogArticles: CmsBlogArticle[]): Article[] => {
  return cmsBlogArticles.map((article) => mapCmsBlogArticleToArticle(article))
}

/**
 * Helper function that maps a CmsBlogExternalArticle object to an Article object
 *
 * @param {CmsBlogExternalArticle} cmsBlogExternalArticle
 * @returns {Article}
 */
export const mapCmsBlogExternalArticleToArticle = (cmsBlogExternalArticle: CmsBlogExternalArticle): Article => {
  return {
    author: {
      firstName: cmsBlogExternalArticle?.attributes?.mediaInstance
    },
    ...(cmsBlogExternalArticle.attributes.category.data && {
      category: {
        slug: cmsBlogExternalArticle.attributes.category.data.attributes.slug,
        title: cmsBlogExternalArticle.attributes.category.data.attributes.title
      }
    }),
    destination: cmsBlogExternalArticle.attributes.url,
    id: cmsBlogExternalArticle.id,
    locale: cmsBlogExternalArticle.attributes.locale as Language,
    publishedAt: cmsBlogExternalArticle.attributes.customPublishedAt ?? cmsBlogExternalArticle.attributes.publishedAt,
    slug: cmsBlogExternalArticle.attributes.url,
    thumbnail: cmsBlogExternalArticle.attributes.thumbnail,
    title: cmsBlogExternalArticle.attributes.title,
    type: CmsArticleTypes.BLOG_EXTERNAL
  }
}

/**
 * Helper function that maps a CmsBlogExternalArticle object to an Article object
 *
 * @param {CmsBlogExternalArticle[]} cmsBlogExternalArticles
 * @returns {Article[]}
 */
export const mapCmsBlogExternalArticlesToArticles = (cmsBlogExternalArticles: CmsBlogExternalArticle[]): Article[] => {
  return cmsBlogExternalArticles.map((article) => mapCmsBlogExternalArticleToArticle(article))
}

/**
 * Helper function that maps a CmsEncyclopediaArticle object to an Article object
 *
 * @param {CmsEncyclopediaArticle} cmsEncyclopediaArticle
 * @returns {Article}
 */
export const mapCmsEncyclopediaArticleToArticle = (cmsEncyclopediaArticle: CmsEncyclopediaArticle): Article => {
  const {
    author,
    customPublishedAt,
    description,
    isHighlighted,
    isHighlightedLanding,
    isPopular,
    locale,
    publishedAt,
    slug,
    subCategory,
    thumbnail,
    title
  } = cmsEncyclopediaArticle.attributes
  const category = subCategory.data?.attributes.category.data?.attributes

  return {
    author: {
      avatarPublicId: author.data?.attributes.avatar.data?.attributes.provider_metadata.public_id ?? null,
      avatarUrl: author.data?.attributes.avatar.data?.attributes.url ?? null,
      firstName: author.data?.attributes.firstName,
      personalizedFooter: author.data?.attributes.personalizedFooter ?? null
    },
    category: {
      color: category.color,
      slug: category.slug,
      title: category.title
    },
    contentBlocks: cmsEncyclopediaArticle.attributes.contentBlocks ?? null,
    description,
    destination: routes(locale, undefined, [category.slug, subCategory.data.attributes.slug, slug]).encyclopediaArticle,
    id: cmsEncyclopediaArticle.id,
    isHighlighted,
    isHighlightedLanding,
    isPopular,
    locale: locale as Language,
    publishedAt: customPublishedAt ?? publishedAt,
    slug,
    subcategory: {
      slug: subCategory.data.attributes.slug,
      title: subCategory.data.attributes.title
    },
    thumbnail,
    title,
    type: CmsArticleTypes.ENCYCLOPEDIA
  }
}

/**
 * Helper function that maps a CmsEncyclopediaArticle object to an Article object
 *
 * @param {CmsEncyclopediaArticle[]} cmsEncyclopediaArticles
 * @returns {Article[]}
 */
export const mapCmsEncyclopediaArticlesToArticles = (cmsEncyclopediaArticles: CmsEncyclopediaArticle[]): Article[] => {
  return cmsEncyclopediaArticles.map((article) => mapCmsEncyclopediaArticleToArticle(article))
}

/**
 * Sorts articles on published date
 *
 * @param {Article[]} articles
 * @param {number} [limit]
 * @returns {Article[]}
 */
export const sortArticlesOnPublishedDate = (articles: Article[], limit?: number): Article[] => {
  const sortedArticles = articles.sort((a, b) => {
    const dateA = dayjs(a.publishedAt)
    const dateB = dayjs(b.publishedAt)

    return dateB.isBefore(dateA) ? -1 : 1
  })

  return limit ? sortedArticles.slice(0, limit) : sortedArticles
}
